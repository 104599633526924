<template>
    <component :is="$route.meta.layout || 'DefaultLayout'">
      <router-view />
    </component>
</template>

<script>
import DefaultLayout from '@/components/layout/defaultLayout.vue'; //기본레이아웃
import LoginLayout from '@/components/layout/loginLayout.vue'; //로그인레이아웃

export default {
  name: 'App',
  components: { 
    DefaultLayout,
    LoginLayout,
  },
  data() {
    return {
      layout: DefaultLayout,
    };
  },
  watch: {
    $route(to, from) {
      this.setLayout(to.meta.layout);
    },
  },
  methods: {
    setLayout(layout) {
      this.layout = layout || DefaultLayout;
    },
  },
  created() {
    this.setLayout(this.$route.meta.layout);
  },
}
</script>

