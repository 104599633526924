<template>
    <div class="topbar">
        <!-- LOGO -->
        <div class="topbar-left">
            <div class="text-center">
                <!-- pc 로고 -->
                <a href="/" class="logo"><img src="@/assets/images/logo.png" height="40"></a>
                <!-- <a href="/" class="logo">FIRST CARE</a> -->
                
                <!-- mob 로고 -->
                <a href="/" class="logo-sm"><img src="@/assets/images/logo.png" height="36"></a>
            </div>
        </div>

        <!-- 사이드 메뉴 버튼 -->
        <div class="navbar navbar-default" role="navigation">
            <div class="container">
                <div class="">
                    <div class="pull-left">
                        <button type="button" class="button-menu-mobile open-left waves-effect waves-light" @click="toggleEnlarged">
                            <i class="ion-navicon"></i>
                        </button>
                        <span class="clearfix"></span>
                    </div>
                </div>
            </div>
        </div>

        <div class="left side-menu">
            <div class="sidebar-inner slimscrollleft">
                <div class="user-details">
                    <div class="user-info">
                        <div class="dropdown">
                            <a href="#" class="dropdown-toggle">{{ adminname }}</a>
                        </div>

                        <p class="text-white m-0">{{ email }}</p>
                    </div>
                </div>

                <div id="sidebar-menu">
                    <ul>
                       <li>
                            <router-link to="/user">
                                <i class="pi pi-user" style="font-size: 1.5rem"></i>
                                <span>회원 관리 </span>
                            </router-link>
                        </li>

                        <li>
                            <router-link to="/organization">
                                <i class="pi pi-building" style="font-size: 1.5rem"></i>
                                <span>오프라인 회원 관리 </span>
                            </router-link>
                        </li>

                        <li>
                            <router-link to="/ticket">
                                <i class="pi pi-ticket" style="font-size: 1.5rem"></i>
                                <span>티켓 관리 </span>
                            </router-link>
                        </li>

                        <li>
                            <router-link to="/payment">
                                <i class="pi pi-verified" style="font-size: 1.5rem"></i>
                                <span>결제 관리 </span>
                            </router-link>
                        </li>

                        <li>
                            <router-link to="/board">
                                <i class="pi pi-inbox" style="font-size: 1.5rem"></i>
                                <span>게시판 관리 </span>
                            </router-link>
                        </li>

                        <!--
                        <li>
                            <router-link to="/banner">
                                <i class="pi pi-clone" style="font-size: 1.5rem"></i>
                                <span>배너 관리 </span>
                            </router-link>
                        </li>
                        -->
                        
                        <li>
                            <router-link to="/contact">
                                <i class="pi pi-question-circle" style="font-size: 1.5rem"></i>
                                <span>문의 관리 </span>
                            </router-link>
                        </li>

                        <li>
                            <router-link to="/admin">
                                <i class="pi pi-cog" style="font-size: 1.5rem"></i>
                                <span>관리자 관리 </span>
                            </router-link>
                        </li>
                    </ul>
                </div>

                <div class="clearfix"></div>
            </div>

            <!-- 로그아웃 -->
            <a href="javascript:;" @click="signOut();" class="logout">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1H12C12.7956 1 13.5587 1.26866 14.1213 1.74688C14.6839 2.2251 15 2.8737 15 3.55V15.45C15 15.6754 14.8946 15.8916 14.7071 16.051C14.5196 16.2104 14.2652 16.3 14 16.3H11" stroke="#83B99C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M1 1L6.3116 2.30469C6.7876 2.42151 7.21594 2.73719 7.52376 3.19803C7.83158 3.65887 7.99987 4.23642 8 4.83242V10.9867V17.141C7.99994 17.2682 7.97691 17.3937 7.93259 17.5085C7.88826 17.6234 7.82374 17.7248 7.74366 17.8053C7.66359 17.8859 7.56995 17.9436 7.46951 17.9744C7.36907 18.0052 7.26432 18.0082 7.1628 17.9833L2.1256 16.7474C1.80811 16.6695 1.52243 16.4589 1.3172 16.1514C1.11197 15.8439 0.999872 15.4586 1 15.0611V1Z" fill="#83B99C" stroke="#83B99C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M18 9.50469H10M10 9.50469L12.3529 7.80469M10 9.50469L12.3529 11.2047" stroke="#83B99C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                <span>로그아웃</span> 
            </a>
        </div>
    </div>
</template>

<script>

import { supabase } from '../../lib/supabaseClient'

export default {
    data() {
        return {
            adminname: "",
            email: "",
        };
    },
    watch: {
        $route(to, from){
            var userInfo = this.$store.getters['user'];
            if(userInfo.data != null){
                this.adminname = userInfo.data.adminname;
                this.email = userInfo.data.email;
            }
        }
    },
    mounted() {

        var userInfo = this.$store.getters['user'];
        if(userInfo.data != null){
            this.adminname = userInfo.data.adminname;
            this.email = userInfo.data.email;
        }

        // 초기에도 윈도우 폭에 따라 enlarged 클래스 추가 또는 제거
        this.handleWindowResize();
        // 윈도우 리사이즈 이벤트에 대한 리스너 등록
        window.addEventListener('resize', this.handleWindowResize);
    },
    beforeDestroy() {
        // 컴포넌트가 파괴되기 전에 윈도우 리사이즈 이벤트 리스너 제거
        window.removeEventListener('resize', this.handleWindowResize);
    },
    methods: {

        // 로그아웃
        async signOut() {
            this.$store.commit('logoutUser');
            let { error } = await supabase.auth.signOut()
            this.$router.push("/login")
        },

        // #wrapper에 클래스 토글하는 메서드 추가
        toggleEnlarged() {
            var wrapper = document.getElementById('wrapper');
            if (wrapper) {
                wrapper.classList.toggle('enlarged');
            }
        },

        alertMessage(msg) {
            this.$swal({
                text: msg,
                padding: '3em',
                fontsize: '16px',
                color: '#000',
            });
        },

        // 윈도우 리사이즈 이벤트에 대한 핸들러
        handleWindowResize() {
            // enlarged 클래스를 윈도우 폭에 따라 추가 또는 제거
            var wrapper = document.getElementById('wrapper');
            if (wrapper) {
                if (window.innerWidth <= 620) {
                    wrapper.classList.add('enlarged');
                } else {
                    wrapper.classList.remove('enlarged');
                }
            }
        },
    }
}
</script>



