<!-- loginLayout.vue -->
<template>
    <div id="wrapper" class="forced loginWrap">
        <router-view />
    </div>
</template>

<script setup>
</script>

