import {
    createRouter,
    createWebHistory
} from 'vue-router';
import DefaultLayout from '@/components/layout/defaultLayout.vue';
import LoginLayout from '@/components/layout/loginLayout.vue';

import { supabase } from '../lib/supabaseClient'
import VueJwtDecode from 'vue-jwt-decode';
import VueCookies from 'vue-cookies';
import store from '../store';

const routes = [

    // home
    {
        path: '/',
        redirect: '/user',
        meta: {
            authRequired: true,
            title: "회원 관리",
        },
    },

    // 회원관리
    {
        path: '/user',
        name: 'user',
        component: () => import('../views/user/list'),
        meta: {
            authRequired: true,
            title: "회원 관리",
        },
    },

    // 결제관리
    {
        path: '/payment',
        name: 'payment',
        component: () => import('../views/payment/list'),
        meta: {
            authRequired: true,
            title: "결제 관리",
        },
    },

    // 티켓관리
    {
        path: '/ticket',
        name: 'ticket',
        component: () => import('../views/ticket/list'),
        meta: {
            authRequired: true,
            title: "티켓 관리",
        },
    },

     // 오프라인 회원 관리
     {
        path: '/organization',
        name: 'organization',
        component: () => import('../views/organization/list'),
        meta: {
            authRequired: true,
            title: "오프라인 회원 관리",
        },
    },

    // 게시판관리
    {
        path: '/board',
        name: 'board',
        component: () => import('../views/board/list'),
        meta: {
            authRequired: true,
            title: "게시판 관리",
        },
    },

    // 배너관리
    {
        path: '/banner',
        name: 'banner',
        component: () => import('../views/banner/list'),
        meta: {
            authRequired: true,
            title: "배너 관리",
        },
    },

    // 문의관리
    {
        path: '/contact',
        name: 'contact',
        component: () => import('../views/contact/list'),
        meta: {
            authRequired: true,
            title: "문의 관리",
        },
    },

    // 관리자관리
    {
        path: '/admin',
        name: 'admin',
        component: () => import('../views/admin/list'),
        meta: {
            authRequired: true,
            title: "관리자 관리",
        },
    },

    // 관리자관리 - 비밀번호 변경
    {
        path: '/resetpw',
        name: 'resetpw',
        component: () => import('../views/admin/resetpw'),
        meta: {
            authRequired: true,
            title: "비밀번호 변경",
        },
    },

    // 로그인
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/account/login'),
        meta: {
            title: "로그인",
            layout: LoginLayout, // 헤더 없는 레이아웃 적용
        },
    },

];

const router = createRouter({
    // mode: 'history',
    history: createWebHistory(),
    linkActiveClass: "active",
    //linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {
                left: 0,
                top: 0
            };
        }
    },
});


router.beforeEach(async (to, from, next) => {
    /*
     * to: 이동할 url 정보가 담긴 라우터 객체
     * from: 현재 url 정보가 담긴 라우터 객체
     * next: to에서 지정한 url로 이동하기 위해 꼭 호출해야 하는 함수
     * next() 가 호출되기 전까지 화면 전환되지 않음
     */

    // to.meta.layout이 설정되어 있다면 해당 레이아웃을 사용
    var layoutComponent = to.meta.layout || DefaultLayout;
    // layoutComponent를 동적으로 설정
    router.options.routes[1].component = layoutComponent;

    if(to.meta.title != undefined){
        document.title = "Tipsbee CMS | " + to.meta.title;
    }else{
        document.title = "Tipsbee CMS";
    }

    var token = VueCookies.get('tipsbeecms_accessToken');
    var refreshToken = VueCookies.get('tipsbeeweb_refreshToken');
    var isVerified = false;

    if(token !== null){
        let decoded = VueJwtDecode.decode(token);
        //console.log(decoded);
        var expTime = decoded.exp;
        var expireDate = new Date(expTime * 1000);
        var today = new Date();
        
        // accessToken 유효한지 검사
        if(expireDate > today){
            isVerified = true;
        }
    }

    if(to.path.includes("login")){
        return next();
    }else{
        if (token !== null){
            // 토큰이 쿠키에 존재하는 경우

            if(isVerified){
                // accessToken 인증된 경우
                
                // Define an async function within the beforeEach hook
                async function checkSession() {
                    try {
                        const sessionInfo = await getSession();

                        if (sessionInfo.success) {
                            var userInfo = {
                                "accessToken": sessionInfo.accessToken,
                                "refreshToken": sessionInfo.refreshToken,
                                "userUqId": VueCookies.get('tipsbeecms_userUqId'),
                                "email": VueCookies.get('tipsbeecms_email'),
                                "adminname": VueCookies.get('tipsbeecms_adminname'),
                                "role": VueCookies.get('tipsbeecms_role'),
                            };

                            //console.log(userInfo);

                            store.commit('setLoggedIn', true);
                            store.commit('setUser', userInfo);

                            return true; // Indicate success
                        } else {
                            // 세션 불러오기 실패 시
                            store.commit('logoutUser')
                            var { error } = supabase.auth.signOut()
                            next("/login");
                            return false; // Indicate failure
                        }
                    } catch (error) {
                        console.error("Error fetching session:", error);
                        next("/login"); // 에러 발생 시 로그인 페이지로 이동
                        return false;
                    }
                    
                }
                // Call the async function and handle its result
                const success = await checkSession();
                if (success) {
                    return next();
                }
                
            }else{
                // accessToken 인증만료된 경우

                // refreshToken 있을 경우 재발급
                if(refreshToken){
                    async function checkRefreshSession() {
                        try {
                            const refreshSessionInfo = await refreshSession(refreshToken);

                            if (refreshSessionInfo.success) {

                                var userInfo = {
                                    "accessToken": refreshSessionInfo.accessToken,
                                    "refreshToken": refreshSessionInfo.refreshToken,
                                    "userUqId": VueCookies.get('tipsbeecms_userUqId'),
                                    "email": VueCookies.get('tipsbeecms_email'),
                                    "adminname": VueCookies.get('tipsbeecms_adminname'),
                                    "role": VueCookies.get('tipsbeecms_role'),
                                };

                                //console.log(userInfo);

                                store.commit('setLoggedIn', true);
                                store.commit('setUser', userInfo);

                                return true; // Indicate success
                            } else {
                                // 세션 불러오기 실패 시
                                store.commit('logoutUser')
                                var { error } = supabase.auth.signOut()
                                next("/login");
                                return false; // Indicate failure
                            }
                        }catch (error) {
                            console.error("Error fetching session:", error);
                            store.commit('logoutUser')
                            var { error } = supabase.auth.signOut()
                            next("/login"); // 에러 발생 시 로그인 페이지로 이동
                        }
                    }
                    const success = await checkRefreshSession();
                    if (success) {
                        return next();
                    }
                    
                }else{
                    store.commit('logoutUser')
                    var { error } = supabase.auth.signOut()
                    next("/login");
                }

            }
            
        }else{
            // 토큰이 쿠키에 없는 경우
            store.commit('logoutUser')
            const { error } = supabase.auth.signOut()

            const authRequired = to.matched.some((route) => route.meta.authRequired)

            if(!authRequired){
                return next();
            }else{
                next("/login");
            }
        }
    }
    
});

// 세션 불러오기
async function getSession(){
    
    const { data, error } = await supabase.auth.getSession()
    if(data && error == null){
        //console.log(data);
        var result = {
            success: true,
            accessToken: data.session.access_token,
            refreshToken: data.session.refresh_token,
        }
        return result;
    }else{
        var result = {
            success: false
        }
        return result;
    }
}

// refresh token으로 세션 다시 생성하기
async function refreshSession(refreshToken){

    const { data, error } = await supabase.auth.refreshSession({
        refresh_token: refreshToken
    })
    if(data && error == null){
        //console.log(data);
        var result = {
            success: true,
            accessToken: data.session.access_token,
            refreshToken: data.session.refresh_token,
        }
        return result;
    }else{
        var result = {
            success: false
        }
        return result;
    }
}

export default router;